import { Routes } from '@angular/router';
import { NotFoundComponent } from '@shared/components/not-found/not-found.component';
import { UnAuthorizedComponent } from '@shared/components/un-authorized/un-authorized.component';

import { HomeComponent } from './home/home.component';
import { SignalrtestComponent } from './signalrtest/signalrtest.component';
import { VerifyOwnerComponent } from './verify-owner/verify-owner.component';
export const AppRoutes: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.routes').then((m) => m.AuthRoutes),
  },
  {
    path: 'verify-owner',
    component: VerifyOwnerComponent,
  },
  {
    path: 'home',
    component: HomeComponent,
  },
  {
    path: 'signalr-test',
    component: SignalrtestComponent,
  },
  {
    path: 'merchants',
    loadChildren: () => import('./merchants/merchant.routes').then((m) => m.MerchantRoutes),
  },
  {
    path: 'legal',
    loadChildren: () => import('./legal-statement/legal.routes').then((m) => m.LegalRoutes),
  },
  {
    path: 'admin',
    loadChildren: () => import('./admin/admin.routes').then((m) => m.AdminRoutes),
  },
  {
    path: 'user',
    loadChildren: () => import('./user/user.routes').then((m) => m.UserRoutes),
  },
  { path: 'not-found', component: NotFoundComponent },
  { path: 'un-authorized', component: UnAuthorizedComponent },
  { path: '**', redirectTo: '/home', pathMatch: 'full' },
];
